import React from "react";
import mdstyle from '../styles/css/content/masterdiv.module.css';

function MasterDivs({ children, hasFlexRow, gapSize, backgroundColor, textAlign,marginBottom, className, marginhorizontal }) {




    return (
        <section className={
              mdstyle.mastercontent + " " + className
          }
            style={{
                backgroundColor: backgroundColor,
                textAlign: textAlign,
                marginBottom: marginBottom,
                marginLeft:marginhorizontal,
                marginRight:marginhorizontal




            }}

        >

            <section className={mdstyle.inlay}>
                {hasFlexRow ? (
                    <section className={mdstyle.flexrow} style={{ gap: gapSize }}>
                        {children}
                    </section>
                ) : (
                    children
                )}
            </section>
        </section>
    );
}

export default MasterDivs;
