import React from "react";
import MasterDivs from "../content/MasterDivs";
import jdstyles from '../styles/css/templates/jobdetail.module.css';
const buildListElements = (content) => {
    if (content) {
        let sepContent = content.split("\n");
        return sepContent.map((item, index) => {
            if (item !== "") {
                return <li key={index}>{item}</li>;
            } else {
                return null;
            }
        });
    } else {
        return null;
    }
};

function JobAdvantage({ advantages, todos, marginhorizontal, advantagetitle, todotitle }) {
    return (
        <MasterDivs hasFlexRow={true} gapSize="15px" marginhorizontal={marginhorizontal}>
            <section className={jdstyles.jobtodos}>
                <h1>{todotitle}</h1>
                <ul className={jdstyles.jobbenefitslist}>{buildListElements(todos)}</ul>
            </section>
            <section className={jdstyles.jobtodos}>
                <h1>{advantagetitle}</h1>
                <ul className={jdstyles.jobbenefitslist}>{buildListElements(advantages)}</ul>
            </section>
        </MasterDivs>
    );
}

export default JobAdvantage;
