import React from "react";
import axios from "axios";
import feapiurl from "../feapiurl";
import MasterDivs from "./MasterDivs";
import frmstyles from "../styles/css/content/form.module.css";

function FormAnswerLogic({ questionid, activePos, setActivePos, formLength,formState, setFormState, setSelectedData, selectedData,
                             setHasAddonQuestion, hasAddonQuestion, selectedAddonData, setSelectedAddonContent }) {
    const answerfetchurl = feapiurl + "getfrmanswers.php";
    const [answers, setAnswers] = React.useState([]);
    const [inputaddonQuestion, setInputAnswerQuestion] = React.useState("");
    const [addonQuestion, setaddonQuestion] = React.useState("");
    const [anzid, setanzid] = React.useState("");

    React.useEffect(() => {
        const getData = async () => {

                const response = await axios.post(answerfetchurl, { questionid });
                const result = response.data;
                setAnswers(result);

        };

        getData();

        return() =>{

        }
    },);


    const handleAnswers = (answerid,isbreakable,addonquestion) =>{
        window.scrollTo({behavior:'smooth',top:0});
if(isbreakable === "1" ){

    setFormState("breakinganswer");
    setActivePos(0);
    setHasAddonQuestion(false);
}

else if (addonquestion){
setaddonQuestion(addonquestion);
    setHasAddonQuestion(true);
    setanzid(answerid);
    setSelectedData(prevSelectedData => {
        return [...prevSelectedData, { questid: questionid, answerid: answerid }];
    });
}


else {
    setHasAddonQuestion(false);



    const updatepos = activePos + 1;
    if (updatepos === formLength) {

        setSelectedData(prevSelectedData => {
            return [...prevSelectedData, { questid: questionid, answerid: answerid }];
        });


        setFormState("finish");

    } else {

        setActivePos(updatepos);

        setSelectedData(prevSelectedData => {
            return [...prevSelectedData, { questid: questionid, answerid: answerid }];
        });




    }

}
}
const handleAddOnQuestion = () =>{

    const updatepos = activePos + 1;
    setActivePos(updatepos);
    setHasAddonQuestion(false);

    setSelectedAddonContent(prevSelectedData  => {
        return [...prevSelectedData, { answerid: anzid, addonAnswercontent:inputaddonQuestion }];
    });
    setInputAnswerQuestion("");

}

    return (
        <MasterDivs hasFlexRow={false}>

            {
                hasAddonQuestion ?(
                <section className={frmstyles.addonform}>
                <h1>{addonQuestion}</h1>

                <textarea
                    onChange={(e)=>setInputAnswerQuestion(e.target.value)}
                className={frmstyles.textarea}
                />

                <button
                className={frmstyles.ctabutton}
                onClick={handleAddOnQuestion}>
                Weiter
                </button>
                </section>
                ):(


            <ul className={frmstyles.answerlist}>

            {answers.map((ans) => (
                <li key={ans.fma_id}
                onClick={()=>handleAnswers(ans.fma_id, ans.fma_isbreakanswer, ans.fma_addonquestion)}
                >{ans.fma_content}</li>
            ))}


            </ul>
                )
            }
        </MasterDivs>
    );
}

export default FormAnswerLogic;
