import React from "react";
import Styles from "../styles/css/templates/footer.module.css"
import Logo from "../globalassets/evolve-logo.svg"
import EvolveLogo from "./EvolveLogo";
import MasterDiv from "../content/MasterDivs";
import FooterNavigation from "./FooterNavgation";
function Footer(){

    return(
       <footer id="footer">
        <section className="inlay">
        <section className={Styles.flexrow}>


<EvolveLogo Logo={Logo}/>
               <FooterNavigation />
        </section>
        </section>


       </footer>

    )


}
export default Footer;