import {Link} from "react-router-dom";
import React from "react";
import ctastyle from "../styles/css/cta-button/ctabutton.module.css";
function CtaButton({source,LinkText,className})
{
        return(

            <Link to={source}
                  className={ctastyle.ctabutton +" "+className}
            >{LinkText}</Link>


        )
}
export default CtaButton;