import React from "react";
import axios from "axios";

import frmstyles from "../styles/css/content/form.module.css";
import CtaButton from "../buttons/CtaButton";


function UserFailed({ headline, content})
{




    return(
                <section className={frmstyles.userformwrapper}>

                        <section className={frmstyles.formsubmitcontent}>

                        <h1>{headline} </h1>

                            <div className={frmstyles.formcontent}
                                dangerouslySetInnerHTML={{ __html: content }}
                            />





                        </section>

                </section>

    )
}
export default UserFailed;