import React from "react";
import videourl from "../videourl";
import playercss from "../styles/css/contentparts/videoplayer.module.css";
function VideoPlayer({videocontent}){
const refVideo = React.useRef();

React.useEffect(()=> {

        refVideo.current.autoplay = false;


        return () => {
        }
    }
    )

const videsource = videourl +'/'+videocontent.pvi_path;
const videoposter = videourl + '/video-thumbnail.webp';
return(

    <section className={playercss.videoframe}>

    <video className={playercss.videoelement}
           ref={refVideo}
           src={videsource}
           poster={videoposter}
           width="100%"
           height="100%"
           autoPlay={false}
controls

           />

    <section className={playercss.videocontent}>




    </section>

    </section>

)

}
export default VideoPlayer