import React from "react";
import jlstyles from "../styles/css/anzeigen/joblocation.module.css"

import MasterDivs from "../content/MasterDivs";
import CtaButton from "../buttons/CtaButton";
function JobSpecialOffer({headline,description,routing,jobid, marginhorizontal}){

    return(
        <MasterDivs backgroundColor="var(--joboffercontent-backgroundcolor)" hasFlexRow={true} marginhorizontal={marginhorizontal}>
              <section className={jlstyles.offercontent}>
              <h2
              style={{
              color:'var(--joboffercontent-headlinecolor)'
              }}>{headline}</h2>
              <p
                  style={{
                      color:'var(--joboffercontent-contentcolor)'
                  }}

              >{description}</p>

    <section className={jlstyles.offerbutton}>
    <CtaButton LinkText="Jetzt Bewerben"  source=  {"/jetzt-bewerben/"+jobid} className={
        jlstyles.buttontag
    }  />
    </section>
              </section>
    </MasterDivs>
    )
}
export default JobSpecialOffer;