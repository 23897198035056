import React  from "react";
import axios from "axios";
import feapiurl from "../feapiurl";
import MasterDivs from "../content/MasterDivs";
import ImageJobsSlider from "../content-parts/ImageJobsSlider";
import imageurl from "../imageurl";
import ImageJobsGallery from "../content-parts/ImageJobsGallery";
import SingleImage from "../content-parts/css/SingleImage";
function LoadImageJobs({ jobdata, mediaType,children, marginBottom, className, marginhorizontal }) {

    const [loadedImages, setLoadedImages] = React.useState([]);

    const fetchurl =feapiurl+ "getjobimages.php";
   React.useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.post(fetchurl);
                const result = response.data;
                const filteredImages = result.filter((img) => img.anz_id === jobdata.anz_id);
console.log(response.data);
                setLoadedImages(filteredImages);
console.log(loadedImages.length);

            }

             catch (error) {
                console.error('Fehler', error);
            }
        };

        getData();
    }, [jobdata.anz_id]);

    return (
        <MasterDivs hasFlexRow={false} backgroundColor="var(--job-detail-imageboxbackground-color)" marginBottom={marginBottom} marginhorizontal={marginhorizontal} className={className}>
            {
                loadedImages.length > 1 ? (
                    mediaType === "slider" ? (
                        <ImageJobsSlider loadedImages={loadedImages} className={"jobslider"} />
                    ) : (
                        <ImageJobsGallery loadedImages={loadedImages} />
                    )
                ) : loadedImages.length === 1 ? (
                    <SingleImage image={loadedImages} />
                ) : (
                   ""
                )
            }

            {children}
        </MasterDivs>
                )


}
export default LoadImageJobs;