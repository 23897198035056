import React, { useState } from "react";
import imageurl from "../imageurl";
import sliderstyles from '../styles/css/contentparts/newslide.module.css';

function ImageSlider({ loadedImages }) {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [transitionValue, setTransitionValue] = useState(0);

    const handlePrevClick = () => {
        if ( currentImageIndex > 0) {
            setIsTransitioning(true);
            const newPosition = currentImageIndex - 1;
            setTransitionValue(-newPosition * 100);
            setCurrentImageIndex(newPosition);
        }
    };

    const handleNextClick = () => {
        if ( currentImageIndex < loadedImages.length - 1) {
            setIsTransitioning(true);
            const newPosition = currentImageIndex + 1;
            setTransitionValue(-newPosition * 100);
            setCurrentImageIndex(newPosition);
        }
    };

    return (
        <section className={`${sliderstyles.imageslider}`}>
            <div className={`${sliderstyles.imagecontainer}`}
                 style={{
                     transform: `translateX(${transitionValue}%)`
                 }}
            >
                {loadedImages.map((image, index) => (
                    <div
                        key={index}
                        className={`${sliderstyles.imageitem} ${index === currentImageIndex ? sliderstyles.current : ""}`}
                    >
                        <img
                            alt={image.paimg_name}
                            src={imageurl + "/" + image.paimg_name}
                            className={sliderstyles.transitionimage}
                        />
                    </div>
                ))}
            </div>

            <div className={sliderstyles.navigation}>
                <button
                    className={`${sliderstyles.buttonprev} ${currentImageIndex === 0 && sliderstyles.buttoninactive}`}
                    onClick={handlePrevClick}
                    disabled={currentImageIndex === 0}
                >
                    Zurück
                </button>
                <button
                    className={`${sliderstyles.buttonnext} ${currentImageIndex === loadedImages.length - 1 && sliderstyles.buttoninactive}`}
                    onClick={handleNextClick}
                    disabled={currentImageIndex === loadedImages.length - 1}
                >
                    Weiter
                </button>
            </div>
        </section>
    );
}

export default ImageSlider;
