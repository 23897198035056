import React from "react";
import LoadPageContent from "../frontenddata/LoadPageContent";

function ContentHome() {
    return (
        <LoadPageContent>
            {(contentData) => (
                <div>
                    {contentData.map((cd) => (
                        <div key={cd.page_id}>
                            <div className="seccontent">
                                {cd.page_content}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </LoadPageContent>
    );
}

export default ContentHome;
