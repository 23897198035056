import React from "react";
import sliderstyles from '../../styles/css/contentparts/imageslide.module.css';
import imageurl from "../../imageurl"; // Annahme: Hier ist die Bild-URL definiert

function SingleImage({ image }) {

    console.log(image);

    return (


            <section className={sliderstyles.singleimage}>
                <img src={imageurl + "/" + image[0].img_path} alt="Bildbeschreibung" />
            </section>

    );

}

export default SingleImage;
