import React from "react";
import jlstyles from "../styles/css/anzeigen/joblocation.module.css"
import {Link} from "react-router-dom";
import CtaButton from "../buttons/CtaButton";
function JobsLocation({jobData, backgroundColor,position}){

    return(


        <section className={jlstyles.jobstylewrapper}
        style={{
            backgroundColor:backgroundColor,
            position:position
        }}>
        <ul
        className={jlstyles.itemlist}>

       <li
        className={jlstyles.itemcity}>
           {jobData.anz_place}

       </li>
       <li
        className={jlstyles.itemjobtype}>
           {jobData.anz_job_type}

       </li>
            <li
        className={jlstyles.itemjobtime}>
           {jobData.anz_start}

       </li>

        </ul>




           <CtaButton LinkText="Jetzt Bewerben"  className={jlstyles.ctabutton} source= {"/jetzt-bewerben/"+jobData.anz_id}  />

        </section>
    )
}
export default JobsLocation;