import React from "react";
import Styles from "../styles/css/templates/header.module.css"

import imageurl from "../imageurl";
import {Link} from "react-router-dom";
function Header(){
const [isFixed, setIsFixed] = React.useState(false);


    return(
        <header className={Styles.header}>
            <section className={Styles.headerlogo}>
<Link to="/">
            <img src={imageurl+"/logo.svg"} alt={"Logo"}/>
</Link>
            </section>
        </header>

    )


}
export default Header;