import React from "react";
import MasterDiv from "./MasterDivs";
import LoadPageContent from "../frontenddata/LoadPageContent";

function Datenschutz(){


    return(
        <MasterDiv hasFlexRow={true}>
            <LoadPageContent pageid="3" contentClass="imprintcontent"/>
        </MasterDiv>
    )

}
export default Datenschutz;
