import React from "react";

import buttonstyle from '../styles/css/content/button.module.css';

function Button({label, source}){


    return(

        <button
            className={buttonstyle.ctabutton}
            >{source}</button>





    )

}
export default Button;
