import React from "react";
import {useParams} from "react-router-dom";
import MasterDivs from "./MasterDivs";
import feapiurl from "../feapiurl";
import axios from "axios";
import FormLogic from "./FormLogic";

function Forms(){

    const { jobid } = useParams();
    const storedJobId = window.localStorage.setItem("jobid", jobid);
    const [formData, setFormData]=  React.useState([]) ;
    const [questionData,setQuestionData] = React.useState([]);
    const [answerData,setAbswerData] = React.useState([]);
    const [formid, setFormid] = React.useState(0);
    const [formResult, setFormResult] = React.useState([]);
    const [activeJob, setActiveJob] = React.useState(parseInt(window.localStorage.getItem("jobid")));
    const [dataStatus, setDataStatus] = React.useState("loading");
    const fetchurl = feapiurl + "getfrmdatabyid.php";
    const questionfetchurl =  feapiurl + "getquestionanswers.php";
    const [activePos, setActivePos] = React.useState(0);
   ;
    // Load Form Data by JobId
    React.useEffect(() => {
document.body.className="formulardata";
document.title ="Bewerbungsformular";

        const getData = async () => {
            try {
                const response = await axios.post(fetchurl, { jobid: activeJob });
                const result = response.data;

                setFormData(result);



                setFormid(result[0].id); // Update formid based on the fetched data
            } catch (error) {
                // Handle error here
            }
        };
        getData(); // Call the getData function here

        return () => {
            // Cleanup if necessary
        }
    }, [activeJob]);
//Load QuestionData by Formular ID
    React.useEffect(() => {
        const getData = async () => {
            try {
                setDataStatus("loading");
                const response = await axios.post(questionfetchurl, { formid });
                const result = response.data;
                setQuestionData(result);

            } catch (error) {

                setDataStatus("error");
            }
        };

        if (formid !== 0) { // Prüfen, ob formid nicht 0 ist, bevor Sie die Daten abrufen

            setDataStatus("OK");

            getData();
        }
    }, [formid]);


    return(
<MasterDivs hasFlexRow={false}>
           <FormLogic questionData={questionData} activePos={activePos} setActivePos={setActivePos} jobid={jobid} dataStatus={dataStatus}/>
</MasterDivs>

)

}
export default Forms;
