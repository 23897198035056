import React from "react";
import masterDivs from "./MasterDivs";
import MasterDiv from "./MasterDivs";
import LoadPageContent from "../frontenddata/LoadPageContent";
function Impressum(){


    return(
        <MasterDiv hasFlexRow={true}>
       <LoadPageContent pageid="2" contentClass="imprintcontent"/>
        </MasterDiv>
            )

}
export default Impressum;
