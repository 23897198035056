import React from "react";
import Styles from "../styles/css/templates/footer.module.css"
function EvolveLogo({Logo})
{
    const weburl = "https://www.evolvejobs.de";

    return(

        <section className={Styles.evolvelogo}>

            <a
                href={weburl}
                target="_blank">

                <img
                    src={Logo}
                    alt="Evolve"
                />



            </a>


        </section>

    )
}
export default EvolveLogo