import './styles/css/App.css';
import Header from "./templates/Header";
import Footer from "./templates/Footer";
import HomeContent from "./content/HomeContent";
import Forms from "./content/Forms";
import Impressum from "./content/Impressum";
import Datenschutz from "./content/Datenschutz";
import JobDetailView from "./anzeigen/JobDetailView";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Header />
                <Routes>
                    <Route path="/" element={<HomeContent />} />
                    <Route path="/:joburl" element={<JobDetailView/>}/>
                    <Route path="/jetzt-bewerben/:jobid" element={<Forms />} />
                    <Route path="/impressum" element={<Impressum />} />
                    <Route path="/datenschutz" element={<Datenschutz/>} />

                 </Routes>
                <Footer />
            </BrowserRouter>
        </div>
    );
}

export default App;
