import React from "react";
import fnstyle from "../styles/css/templates/footer.module.css"
import {Link} from "react-router-dom";
function FooterNavigation(){

 const [isActive, setIsActive] = React.useState(false);

 const toogleActivc = (index) =>{


 }

    return(
        <ul className={fnstyle.footernavigation}>
        <li><Link onClick={()=>toogleActivc(1)} key="1" to="/datenschutz">Datenschutz</Link> </li>
        <li> <Link onClick={()=>toogleActivc(2)}key="2" to="/impressum">Impressum</Link> </li>
        </ul>



    )

}
export default FooterNavigation;