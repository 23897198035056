import imageurl from "../imageurl";
import React, { useState } from "react";
import sliderstyles from '../styles/css/contentparts/imageslide.module.css';


function ImageGallery({ loadedImages, className }) {


    return (
        <section className={sliderstyles.imagegallery + " " + className}

                 style={{marginBottom: '0px'}}
        >
            <ul>
                {
                    loadedImages.map((img) => (

                            <li key={img.paimg_id}>
                                <img
                                    alt={img.paimg_name}
                                    src={imageurl + "/" + img.paimg_name}
                                />
                            </li>
                        )
                    )}


            </ul>
        </section>
    )
}

export default ImageGallery;
