import React from "react";
import  jdstyle from '../styles/css/contentparts/jobduration.module.css';
function JobDurationContent({ headline,content }){




    return(
        <section className={jdstyle.jobdurationcontainer}>
            <h3>{headline}</h3>
            <p
                dangerouslySetInnerHTML={{ __html: content }}
            />

        </section>

    )
}
export default JobDurationContent