import React, { useState, useEffect } from "react";
import frmstyle from "../styles/css/content/form.module.css";
import FormAnswerLogic from "./FormAnswerLogic";
import MasterDivs from "./MasterDivs";
import UserForm from "./UserForm";
import UserFailed from "./UserFailed";
import feapiurl from "../feapiurl";
import axios from "axios";

function FormLogic({ questionData, activePos, setActivePos, jobid }) {
    const [activeQuestion, setActiveQuestion] = useState(null);
    const [formLength, setFormLength] = useState(0);
    const [formState, setFormState] = useState('inprogress');
    const [selectedData, setSelectedData] = useState([]);
    const [selectedAddonData, setSelectedAddonContent] = React.useState([]);
    const [hasAddonQuestion, setHasAddonQuestion] = React.useState(false);
    const [pageContentData, setPageContentData] = React.useState([]);

    const areaname = "Formular";
    const pcurl = feapiurl + "getpagecontentbyarea.php";

    React.useEffect(() => {


    });
    React.useEffect(() => {
        const pcData = async () => {
            try {
                const response = await axios.post(pcurl, { areaname });
                const result = response.data;
                setPageContentData(result);
            } catch (error) {
                console.error('Fehler', error);
            }
        };

        pcData();
    }, [areaname]);

    useEffect(() => {
        // Ensure that questionData[actpos] exists before updating activeQuestion
        if (questionData.length > 0 && questionData[activePos]) {
            setActiveQuestion(questionData[activePos].fmq_id);
            setFormLength(questionData.length);
        }
    }, [activePos, questionData]);


    return (
        <section className={frmstyle.formlogicwrapper}>
            {formState === "inprogress" ? (
                questionData.length > 0 ? (
                    <section className={frmstyle.questionarea}>

                        {
                            hasAddonQuestion
                            ?(""):(
                                <>
                        <p>Frage {activePos + 1} von {formLength}</p>
                        <h1 className={frmstyle.question}>{questionData[activePos].fmq_question}</h1>
                                </>
                            )}

                        <section className={frmstyle.answerslogic}>
                            <FormAnswerLogic
                                questionid={activeQuestion}
                                formState={formState}
                                setFormState={setFormState}
                                formLength={formLength}
                                activePos={activePos}
                                setActivePos={setActivePos}
                                selectedData = {selectedData}
                                setSelectedData = {setSelectedData}
                                hasAddonQuestion = { hasAddonQuestion }
                                setHasAddonQuestion = {setHasAddonQuestion}
                                selectedAddonData = { selectedAddonData}
                                setSelectedAddonContent = {setSelectedAddonContent}
                            />
                        </section>
                    </section>
                ) : (
                    <p></p>
                )
            ) : formState === "finish" ? (
              <MasterDivs hasFlexRow={false}>
                  {pageContentData[1] && (


            <UserForm jobid={jobid} selectedData={selectedData} selectedAddon = {selectedAddonData} formheadline={pageContentData[1].headline}

                formcontent={pageContentData[1].text} sendtitle={pageContentData[2].headline} sendcontent={pageContentData[2].text
            }/>
                      )}
              </MasterDivs>

            ) : formState === "breakinganswer" ? (
               <MasterDivs hasFlexRow={false}>

                   {pageContentData[0] && (


<UserFailed headline={pageContentData[0].headline} content={ pageContentData[0].text }/>
                       )}
               </MasterDivs>
            ) : null }


        </section>
    );
}

export default FormLogic;
