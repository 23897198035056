import React  from "react";
import axios from "axios";
import feapiurl from "../feapiurl";
import MasterDivs from "../content/MasterDivs";
function LoadPageContent({ pageid, hasFlexRow, backGroundColor, contentClass, marginBottom, marginhorizontal }) {

    const [loadedContent, setLoadedContent] = React.useState([]);

    const fetchurl =feapiurl+ "getpages.php";
   React.useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.post(fetchurl);
                const result = response.data;
                const filteredContent = result.filter((page) => page.page_id === pageid);

                setLoadedContent(filteredContent);


            }

             catch (error) {
                console.error('Fehler', error);
            }
        };

        getData();
    }, [pageid]);

    return (
        <MasterDivs hasFlexRow={hasFlexRow} backgroundColor={backGroundColor} marginBottom={marginBottom} marginhorizontal={marginhorizontal}>

            {loadedContent.map((cd) => (

                    <div className={contentClass} key={cd.page_id}  dangerouslySetInnerHTML={{ __html: cd.page_content }} />

            ))}

        </MasterDivs>
                )


}
export default LoadPageContent;